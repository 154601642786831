import type { HeadersArgs, HeadersFunction, LoaderFunctionArgs, TypedResponse } from '@remix-run/cloudflare';
import { json, redirect } from '@remix-run/cloudflare';
import { useLoaderData } from '@remix-run/react';
import { StandardError } from '~/components/errors';
import { EmptyLayout, Layout } from '~/components/layouts';
import { CarsIndex } from '~/components/pages';
import { Loading } from '~/components/parts';
import { graphql } from '~/gql/generated';
import { CarSalesStatusEnum, type CarStocksIndexPageQuery } from '~/gql/generated/graphql';
import { ssrClient } from '~/graphql/client';
import { getEnvironmentVariables } from '~/infrastructure/environmentVariables';
import { loaderErrorHandle } from '~/infrastructure/errorHandle';
import { noCache } from '~/infrastructure/header';

const FIRST_FETCH_CAR_STOCKS_COUNT = 10;

const carStocksIndexPageQueryDocument = graphql(`
  query CarStocksIndexPage($first: Int, $salesStatus: CarSalesStatusEnum) {
    carStocks(first: $first, salesStatus: $salesStatus) {
      edges {
        node {
          id
          landTransportCosts {
            cost
            prefectureCode
          }
          ...CarsIndex_carStock
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

type CarStockType = NonNullable<
  NonNullable<NonNullable<CarStocksIndexPageQuery['carStocks']['edges']>[number]>['node']
>;

type LoadData = {
  carStocks: CarStockType[];
  hasError: boolean;
  isLiffRedirected: boolean;
};

export const loader = async ({ context, request }: LoaderFunctionArgs): Promise<TypedResponse<LoadData>> => {
  const env = getEnvironmentVariables(context);
  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const liffState = searchParams.get('liff.state');
  const redirectPath = searchParams.get('redirectPath');

  if (liffState !== null) {
    return json<LoadData>(
      {
        carStocks: [],
        hasError: false,
        isLiffRedirected: true
      },
      {
        headers: { 'Cache-Control': noCache }
      }
    );
  }

  if (redirectPath) {
    throw redirect(redirectPath);
  }

  const variables = {
    salesStatus: CarSalesStatusEnum.OnSale,
    first: FIRST_FETCH_CAR_STOCKS_COUNT
  };
  const graphqlClient = ssrClient({ baseUrl: env.graphqlBaseUrl });
  const res = await graphqlClient.query(carStocksIndexPageQueryDocument, variables);
  const hasError = !!res.error;

  if (hasError) {
    throw loaderErrorHandle(res.operation.context.url, res.error!);
  }

  return json<LoadData>(
    {
      carStocks: (res.data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) ||
        []) as CarStockType[],
      hasError: hasError,
      isLiffRedirected: false
    },
    {
      headers: { 'Cache-Control': hasError ? noCache : 'public, max-age=0, s-maxage=60' }
    }
  );
};

export const headers: HeadersFunction = ({ parentHeaders, loaderHeaders }: HeadersArgs) => {
  const loaderCacheControl = loaderHeaders.get('cache-control');

  return {
    ...parentHeaders,
    'Cache-Control': loaderCacheControl || 'public, max-age=0, s-maxage=60'
  };
};

export default function Index() {
  const data = useLoaderData<typeof loader>();

  if (data.isLiffRedirected) {
    return (
      <EmptyLayout>
        <Loading isFull />
      </EmptyLayout>
    );
  }

  if (data.hasError) {
    return (
      <Layout>
        <StandardError />
      </Layout>
    );
  }

  return (
    <Layout>
      <CarsIndex newCarStocks={data.carStocks} />
    </Layout>
  );
}
